<template>
    <enso-table class="box is-paddingless raises-on-hover"
        id="news"/>
</template>
<script>

import { EnsoTable } from '@enso-ui/tables/bulma';

export default {
    name: 'Index',
    components: { EnsoTable },
};
</script>

<style lang="scss">
</style>
